import React from "react";
import { Box, Chip, LinearProgress, Typography } from "@mui/material";
import "./AIGurujiKundliCardInfo.scss";

const AIGurujiKundliCardInfo: React.FC<any> = ({
  data,
  imageurl,
  selectedLang,
}) => {
  const labels = {
    EN: {
      fullName: "Full Name*",
      fullNamePlaceholder: "Enter here",
      dateOfBirth: "Date of Birth*",
      gender: "Gender",
      male: "Male",
      female: "Female",
      others: "Others",
      preferNotToSay: "Prefer not to say",
      timeOfBirth: "Time of Birth (HH:MM)*",
      birthLocation: "Birth Location*",
      cityPlaceholder: "Start typing city...",
      generate: "Generate",
      title: "Enter Your Details",
      selectGender: "Select here",
      horoscope: "GENERATE ASTROLOGY",
      numerology: "GENERATE NUMEROLOGY",
      fullNameError: "Full Name is required",
      dateOfBirthError: "Date of Birth is required",
      timeOfBirthError: "Time of Birth is required",
      birthLocationError: "Birth Location is required",
    },
    HI: {
      fullName: "पूरा नाम*",
      fullNamePlaceholder: "यहां दर्ज करें",
      dateOfBirth: "जन्म तिथि*",
      gender: "लिंग",
      male: "पुरुष",
      female: "महिला",
      others: "अन्य",
      preferNotToSay: "नहीं कहना पसंद करते हैं",
      timeOfBirth: "जन्म का समय (HH:MM)*",
      birthLocation: "जन्म स्थान*",
      cityPlaceholder: "शहर लिखना प्रारंभ करें...",
      generate: "उत्पन्न करें",
      title: "अपनी जानकारी दर्ज करें",
      selectGender: "यहाँ चुनें",
      horoscope: "राशिफल जाने",
      numerology: "अंकज्योतिष जाने",
      fullNameError: "पूरा नाम आवश्यक है",
      dateOfBirthError: "जन्मतिथि आवश्यक है",
      timeOfBirthError: "जन्म का समय आवश्यक है",
      birthLocationError: "जन्म स्थान आवश्यक है",
    },
  };

  const langLabels =
    selectedLang && labels[selectedLang === "EN" ? "EN" : "HI"]
      ? labels[selectedLang === "EN" ? "EN" : "HI"]
      : labels.EN;
  return (
    <Box className="kundliCardListSec">
      {imageurl && (
        <Box className="cardTopImg">
          <Box
            component="img"
            src={imageurl}
            alt="Card Icon"
            className="kundliCardImg"
          />
        </Box>
      )}

      <Typography variant="h6" className="cardTopTitle">
        {data?.title}
      </Typography>
      <Box className="listArea">
        {data?.lagna_compatibility && (
          <Typography className="highlightedPart">
            {data?.pills_title ? data?.pills_title + ": " : ""}
            <span className="capitalText">{data?.lagna_compatibility} </span>
          </Typography>
        )}
        {data?.guna_milan_score && data?.total_possible_score && (
          <Typography className="highlightedPart">
            {data?.pills_title ? data?.pills_title + "  " : ""}
            <span className="capitalText">
              {data?.guna_milan_score} /{data?.total_possible_score}
            </span>
          </Typography>
        )}
        {data?.fifth_house_analysis && (
          <Typography className="descriptionInfo">
            {data?.fifth_house_analysis}
          </Typography>
        )}
        {data?.relationship_benefic_influence !== undefined && (
          <Typography className="highlightedPart">
            {data?.pills_title ? data?.pills_title + ": " : ""}
            <span>{data?.relationship_benefic_influence ? "YES" : "NO"}</span>
          </Typography>
        )}
        {data?.recommended_period && (
          <Typography className="highlightedPart">
            {data?.pills_title ? data?.pills_title + ": " : ""}
            <Typography className="capitalText">
              {data?.recommended_period}
            </Typography>
          </Typography>
        )}
        {data?.birth_star_compatibility && (
          <Box className="listArea">
            <Typography className="highlightedPart">
              {data?.pills_title ? data?.pills_title + ": " : ""}
              <span className="capitalText">
                {data.birth_star_compatibility}
              </span>
            </Typography>
          </Box>
        )}
        {(data?.career_outlook || data?.financial_outlook) && (
          <Box className="listArea">
            {data.career_outlook && (
              <Typography className="highlightedPart">
                {data?.pills_title ? data?.pills_title + ": " : ""}
                <span className="capitalText">{data.career_outlook}</span>
              </Typography>
            )}
            {data.financial_outlook && (
              <Typography className="highlightedPart">
                {data?.pills_title ? data?.pills_title + ": " : ""}
                <span className="capitalText">{data.financial_outlook}</span>
              </Typography>
            )}
          </Box>
        )}
        {data?.recommended_gemstones &&
          data.recommended_gemstones.length > 0 && (
            <Box className="listArea">
              <Box className="highlightedPart">
                <Typography className="titlePart">
                  {data?.pills_title ? data?.pills_title : ""}
                </Typography>
                <ul>
                  {data.recommended_gemstones.map((gem: any, index: any) => (
                    <li key={index}>{gem}</li>
                  ))}
                </ul>
              </Box>
            </Box>
          )}
        {data?.remedies && data.remedies.length > 0 && (
          <Box className="listArea">
            <Box className="highlightedPart">
              <Typography className="titlePart">
                {" "}
                {data?.pills_title ? data?.pills_title : ""}
              </Typography>
              <ul className="pNone">
                {data.remedies.map((remedy: any, index: any) => (
                  <li key={index}>{remedy}</li>
                ))}
              </ul>
            </Box>
          </Box>
        )}

        {data?.compatibility_score !== undefined && (
          <Box className="listArea">
            <Typography className="highlightedPart">
              {data?.pills_title
                ? `${data?.pills_title} : ${data.compatibility_score}`
                : `${data.compatibility_score}`}
            </Typography>
          </Box>
        )}

        {data?.rashi_compatibility && (
          <Box className="listArea">
            <Typography className="highlightedPart">
            {data?.pills_title ? data?.pills_title + ": " : ""}
              <span className="capitalText">
               
              {data.rashi_compatibility}
              </span>
            </Typography>
          </Box>
        )}

        {data?.is_present !== undefined && (
          <Box className="listArea">
            <Typography className="highlightedPart">
              {data?.pills_title
                ? `${data?.pills_title} : ${data?.is_present ? "YES" : "NO"}`
                : `${data?.is_present ? "YES" : "NO"}`}
            </Typography>
          </Box>
        )}

        {data?.description && (
          <Typography className="descriptionInfo">
            {data?.description}
          </Typography>
        )}

        {data?.dasha_system_analysis && (
          <Typography className="descriptionInfo">
            {data?.dasha_system_analysis}
          </Typography>
        )}

        {data?.marriage_house_analysis && (
          <Typography className="descriptionInfo">
            {data?.marriage_house_analysis}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default AIGurujiKundliCardInfo;
