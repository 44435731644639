import {createSlice} from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: null,
    isLoading: false,
    error: null,
    addressList: [],
    allOrganisationUser: [],
    currentAddress: null,
    allNormalUsers : [],
    selectedAddressId:null,
    currentGPSAddress:null
  },
  reducers: {
    setUser: (state, action) => {
      state.userInfo = action.payload;
    },
    setSelectedAddressId: (state, action) => {
      state.selectedAddressId = action.payload;
    },
    
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setAddressList: (state, action) => {
      state.addressList = action.payload;
    },
    setCurrentAddress: (state, action) => {
      const selectedUserAddress = state.addressList?.filter((item:any)=>item.id === action.payload);
      if(selectedUserAddress[0]){
        state.currentAddress =   selectedUserAddress[0]
      }else{
        state.currentAddress =  state.currentGPSAddress;
      }
    },
    setCurrentGPSAddress: (state, action) => {
      state.currentGPSAddress = action.payload;
    },
    
    setAllOrganisationUser: (state, action) => {
      state.allOrganisationUser = action.payload;
    },
    setAllNormalUser: (state, action) => {
      state.allNormalUsers = action.payload;
    },
    
  },
});

export const {
  setUser,
  setLoading,
  setError,
  setAddressList,
  setCurrentAddress,
  setCurrentGPSAddress,
  setAllOrganisationUser,
  setAllNormalUser,
  setSelectedAddressId
} = userSlice.actions;

export default userSlice.reducer;
