import PersonPinCircleOutlinedIcon from "@mui/icons-material/PersonPinCircleOutlined";
import AddIcon from "@mui/icons-material/Add";
import EastIcon from "@mui/icons-material/East";
import "./ChooseAddress.scss";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { chooseDeliveryAddressTitle } from "../../../src/utils/ConstantsMessages";
import CloseIcon from '@mui/icons-material/Close';

function ChooseAddress(props: any) {
  const navigate = useNavigate();
  const addressList =
    props.userPersonalInfo && props.userPersonalInfo.address
      ? props.userPersonalInfo.address
      : [];

  console.log("address list", addressList);
  return (
    <>
      <Box className="chooseDeliveryAddressArea">
        <Stack 
          direction="row" 
          justifyContent="space-between" 
          alignItems="center"
          className="topHeader"
        >
          <Typography className="deliveryAddressTitle">
            {chooseDeliveryAddressTitle.chooseTitle}
          </Typography>
          <IconButton 
            onClick={() => props.setChooseAddressBox(false)} 
            className="topCloseIcon"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        {addressList.map((item: any, id: any) => {
          return (
            <Box
              className="addressArea"
              onClick={() => {
                props.changeDeliverytAddress(item);
                props.setChooseAddressBox(false);
              }}
            >
              <Box className="addressDetail">
                <Box className="addressLeft">
                  <Box className="addressIcon">
                    <PersonPinCircleOutlinedIcon />
                  </Box>
                  <Box className="addressText">
                    <Box className="addressHeading">
                      <Typography className="title">
                        {item.savedAs || item.name}
                      </Typography>
                    </Box>

                    <Box className="detailAddress">
                      <Typography>{item.formatted_address}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="addressRight">
                  <IconButton type="button">
                    <EastIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          );
        })}
        {/* <Box
          className="addAddressArea borderAdd"
          onClick={() => navigate("/profile/address")}
        >
          <Box className="addressDetail">
            <Box className="addressLeft">
              <Box className="addressIcon">
                <AddIcon />
              </Box>
              <Box className="addressText">
                <Box className="addressText">
                  <Typography style={{ fontWeight: "bold" }}>
                    Add new address
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="addressRight">
              <Button type="button"></Button>
            </Box>
          </Box>
        </Box> */}
        <Box className="addAddressArea">
          <Button 
            variant="text" 
            startIcon={<AddIcon />}
            onClick={() => navigate("/profile/address")}
          >
            Add new address
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default ChooseAddress;
