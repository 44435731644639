
import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "./AIGurujiServiceStartText.scss";
import { suggestionLabels } from "../../../../utils/ConstantsMessages";

const AIGurujiServiceStartText = ({ selectedLang }: { selectedLang: string }) => {
  const [langLabels, setLangLabels] = useState<string | null>(null);

  useEffect(() => {
    setLangLabels(suggestionLabels[selectedLang] || suggestionLabels["EN"]); 
  }, [selectedLang]);

  return (
    <Box className="serviceSuggestionArea">
      <Typography className="serviceText">
        {langLabels}
      </Typography>
    </Box>
  );
};

export default AIGurujiServiceStartText;
