const POP_UP_MESSAGE = {
  replacePujaOrChadhavaMessage:
    "A puja/chadhava has already been added to your cart. Would you like to replace the puja/chadhava with selected puja/chadhava ?",
};

const CalculationChart = {
  description:
    "All calculations and charts are derived from the following input:",
  editText: "Edit Details",
  dateOfBirth: "Date of Birth",
  gender: "Gender",
  timeOfBirth: "Time of Birth",
  cityOfBirth: "City of Birth",
  countryOfBirth: "Country of Birth",
};

const labels = {
  EN: {
    fullName: "Full Name*",
    fullNamePlaceholder: "Enter here",
    dateOfBirth: "Date of Birth*",
    timeOfBirth: "Time of Birth (HH:MM)*",
    birthLocation: "Birth Location*",
    cityPlaceholder: "Start typing city...",
    generate: "Generate",
    title: "Enter Birth Details",
    KundaliMatchingTitle: "Enter Birth Details (Guna Milan)",
    fullNameError: "Full Name is required",
    dateOfBirthError: "Date of Birth is required",
    timeOfBirthError: "Time of Birth is required",
    birthLocationError: "Birth Location is required",
    matchKundli: "Match Kundli",
    GirlsBirthDetails: "Girl’s Birth Details",
    girlFullName: "Girl's Full Name*",
    girlFullNamePlaceholder: "Enter girl's name",
    BoysBirthDetails: "Boy’s Birth Details",
    boyFullName: "Boy's Full Name*",
    boyFullNamePlaceholder: "Enter boy's name",
    gender: "Gender",
    male: "Male",
    female: "Female",
    others: "Others",
    selectGender: "Select here",
    preferNotToSay: "Prefer not to say",
    horoscope: "GENERATE ASTROLOGY",
    numerology: "GENERATE NUMEROLOGY",
 
  },
  HI: {
    fullName: "पूरा नाम*",
    fullNamePlaceholder: "यहां दर्ज करें",
    dateOfBirth: "जन्म तिथि*",
    timeOfBirth: "जन्म का समय (HH:MM)*",
    birthLocation: "जन्म स्थान*",
    cityPlaceholder: "शहर लिखना प्रारंभ करें...",
    generate: "उत्पन्न करें",
    title: "अपनी जानकारी दर्ज करें",
    KundaliMatchingTitle: "जन्म विवरण दर्ज करें (गुण मिलान)",
    fullNameError: "पूरा नाम आवश्यक है",
    dateOfBirthError: "जन्मतिथि आवश्यक है",
    timeOfBirthError: "जन्म का समय आवश्यक है",
    birthLocationError: "जन्म स्थान आवश्यक है",
    matchKundli: "कुंडली मिलाएं",
    GirlsBirthDetails: "लड़की का जन्म विवरण",
    girlFullName: "लड़की का पूरा नाम*",
    girlFullNamePlaceholder: "लड़की का नाम दर्ज करें",
    BoysBirthDetails: "लड़के का जन्म विवरण",
    boyFullName: "लड़के का पूरा नाम*",
    boyFullNamePlaceholder: "लड़के का नाम दर्ज करें",
    gender: "लिंग",
    male: "पुरुष",
    female: "महिला",
    others: "अन्य",
    selectGender: "यहाँ चुनें",
    preferNotToSay: "नहीं कहना पसंद करते हैं",
    horoscope: "राशिफल जाने",
    numerology: "अंकज्योतिष जाने",
    
  },
};
 const suggestionLabels: { [key: string]: string } = {
  EN: "Click on a Service below to start chatting with Guruji:",
  HI: "गुरुजी से बातचीत शुरू करने के लिए नीचे दी गई सेवा पर क्लिक करें:",
  loading: "Loading..."
};


const TableName ={
  girlNameTitle:"Girl’s Name",
  boyNameTitle: "Boy’s Name"
};

const GurujiHomeIcon = {
  valmikiChat: "Chat With Valmiki",
  liveCount:"2K ONLINE"
};

const ADDRESS_PAGE_LABELS = {
  addNewAdressButton: "Add New address",
  streetLabel: "Street",
  cityLabel: "City",
  areaLabel: "Area",
  stateProvinceAreaLabel: "State/province/area",
  phoneNumberLabel: "Phone Number",
  zipCodeLabel: "Zip code",
  locationButtonLabel: "Use My current location",
  nameLabel: "Name",
  streetHouseAptLabel: "Street/House/Apt No",
  cityDistrictTownLabel: "City/District/Town",
  stateLabel: "State",
  pinCodeLabel: "Pincode",
  alternatePhoneNumberLabel: "Alternate Phonenumber",
  formattedAddressLabel: "Formatted Address",
  saveButtonLabel: "Save",
  cancelButtonLabel: "Cancel",
  editAddresLabel: "Edit Address",
  addressNameErrorMessage: "Name is required.",
  cityErrorMessage: "City/District/Town is required.",
  stateErrorMessage: "State is required.",
  zipCodeErrorMessage: "Zip code is required.",
  areaErrorMessage: "Area is required.",
  invalidPhoneNumberMessage: "Invalid phone number format.",
  countyCode: "Country Code"
  
}

const ADD_DEVOTEE_LABEL = {
  pujaNameLabel : "Puja Name",
  templeNameLabel: "Temple Name",
  pujaDateLabel: "Puja Date",
  whatsAppNumberLabel: "Provide Your WhatsApp Number *",
  whatsAppNumberErrorMessage:"Please enter a valid 10-digit WhatsApp number.",
  addDevoteeFromPreviousBookingLabel: "Add devotee from previous bookings",
  devoteeNameError: "Please enter your full name",
  gotramLabel: 'Gotram (Default Kashyap)',
  gotramCheckboxLabel: "Don’t know my Gotram",
  manokamnaLabel: "Manokamna/ Wish (Optional)",
  addDevoteeDialogTitle: "Previous Booking Devotee List",
  addDevoteeDialogContinueButtonLabel:"Select & Continue",
  saveAndContinueButtonLabel:"Save And Continue"
}

const chooseDeliveryAddressTitle = {
  chooseTitle: "Choose a delivery address",
};

const disclaimerText = {
  textMessage: "Prices may vary depending on your Country",
};

const disclaimerPrasadText = {
  textMessage: "Prasad delivery is only available in India. Outside India, this service is not offered.",
};

const languageChooseText = {
  textMessage: "Please choose your preferred language to chat with Valmiki / वाल्मिकी के साथ चैट करने के लिए कृपया अपनी पसंदीदा भाषा चुनें।",
};

export { CalculationChart, POP_UP_MESSAGE, labels, TableName, ADDRESS_PAGE_LABELS, ADD_DEVOTEE_LABEL, suggestionLabels, GurujiHomeIcon, chooseDeliveryAddressTitle, disclaimerText, disclaimerPrasadText, languageChooseText };

