import React from "react";
import "./AIGurujiReligiousChat.scss";
import { Button, Stack, Box, Avatar, Typography, Grid } from "@mui/material";
import Nameste from "../../../../assets/nameste.png";
import valmikiImg from "../../../../assets/valmikiImg.png";
import musicicon from "../../../../assets/music icon.png";
import gpticon from "../../../../assets/gpticon.png";
import AIGurujiValmikiImg from "../AIGurujiValmikiImg/AIGurujiValmikiImg";
import AIGurujitext from "../AIGurujitext/AIGurujitext";
const AIGurujiReligiousRowChat: React.FC<any> = ({ availableActions, onMenuSelect, isDisabled, direction,selectedServices }) => {
  const handleOnMenuSelect = (item: any) => {
    if (!isDisabled) {
      onMenuSelect(item);
    }

  }

  const isRow = direction === 'row';
  return (
    <Box className="religiousChatContainer" mb="50px" sx={{ display: "inline-block" }}>
      <Box className={`messageContainer ${isRow ? '' : 'left'}`}>
        <Grid
         className="chatItemSuggestionBtn"
         container
          direction={isRow ? 'row' : 'column'}
          spacing={2}
          justifyContent={isRow ? 'flex-end' : 'left'}
          alignItems={isRow ? 'flex-end' : 'left'}
        >
         
            {availableActions?.map((item: any, index = 0) => (
              <Grid item xs={6} md={6} lg={6} xl={6} className={`${selectedServices === item?.extra_parameters?.service ? 'dNone' : ''}`}>
              <Box className={`messageBox whiteBox fullWidthCenter`} 
              key={`ReligiousChat${index}`} >
                <Typography className="message-text" sx={{ cursor: "pointer" }} onClick={e => { e.preventDefault(); handleOnMenuSelect(item) }}>
                  

                  <Box component="img"
                    src={item?.icon}
                    alt={item?.name}
                    
                  >
                  </Box>
                  {item?.name}
                </Typography>
              </Box>
              </Grid>
            ))}
          
        </Grid>
      </Box>

    </Box>
  );
};

export default AIGurujiReligiousRowChat;
