import React, { useEffect, useState } from "react";
import "./AIGurujiLanguageSelect.scss";
import { Button, Stack, Box, Avatar, Typography, ButtonGroup } from "@mui/material";
import AIGurujiValmikiImg from "../AIGurujiValmikiImg/AIGurujiValmikiImg";
import { languageChooseText } from "../../../../utils/ConstantsMessages";


const AIGurujiLanguageSelect: React.FC<any> = (props) => {
  const [language, setLanguage] = useState<string>("");
  const handleLanguageSwitch = (lang: string) => {
    setLanguage(lang);
    props?.onLanguageSwitch(lang)
  };
   
  useEffect(() => {
    setLanguage(props?.lang || '');
  }, [props?.lang])
  
  return (
    <>

      <Box className="languageSelectContainer">
        <Stack
          className="languageSelectText"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Typography className="engLanguage">{languageChooseText.textMessage}</Typography>
          {/* <Typography className="hindiLanguage">कृपया अपनी भाषा चुनें</Typography> */}
          <ButtonGroup className="btnGroupLang">
            {/* EN "A" Button */}
            <Button
              onClick={(e) => { e.preventDefault(); handleLanguageSwitch("EN") }}
              sx={{
                backgroundColor: language === "EN" ? "#d56939" : "white",
                color: language === "EN" ? "white" : "black",
                borderRadius: "20px 0 0 20px",
                padding: "10px 20px",
                textTransform: "none",
                fontSize: "18px",
                cursor: "pointer",
                '&:hover': {
                  backgroundColor: language === "EN" ? "#d56939" : "white",
                },
              }}
            >
              A
            </Button>

            <Button
              onClick={(e) => { e.preventDefault(); handleLanguageSwitch("HI") }}
              sx={{
                backgroundColor: language === "HI" ? "#d56939" : "white",
                color: language === "HI" ? "white" : "black",
                borderRadius: "0 20px 20px 0",
                padding: "10px 20px",
                textTransform: "none",
                fontSize: "18px",
                cursor: "pointer",
                '&:hover': {
                  backgroundColor: language === "HI" ? "#d56939" : "white",
                },
              }}
            >
              अ
            </Button>
          </ButtonGroup>
        </Stack>
        
        {/* <Stack
        className="languageSelectBtn"
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        spacing={2}
      >
        <Button variant="contained">EN</Button>
        <Button variant="contained">हिंदी</Button>
      </Stack> */}
      </Box>
      {/* <Box className="valmikiImage">
    <AIGurujiValmikiImg />
  </Box> */}
  </>

  );
};

export default AIGurujiLanguageSelect;
