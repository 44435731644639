import { useEffect, useState } from "react";
import AddressView from "../../../../Views/Profile/Views/Address/AddressView";
import { getFormattedAddress, uid, validatePhoneNumber } from "../../../../utils/HelperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { UserService } from "td_server";
import {
  addAddressAction,
  deleteUserAddressAction,
  getUserGPSAddressAction,
} from "../../../../store/actions/userAction";
import { ADDRESS_PAGE_LABELS } from "../../../../utils/ConstantsMessages";
import {  setSelectedAddressId, setUser } from "../../../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
function AddressScreen() {
  const { userInfo, selectedAddressId,currentGPSAddress } = useSelector((state: any) => state.user);
  const userPersonalInfo =
    userInfo && userInfo.personalInfo ? userInfo.personalInfo : {};
  const dispatch: any = useDispatch();
  const [createNewOrUpdateAddressView, setCreateNewOrUpdateAddressView] =
    useState({ isCreate: false, isUpdate: false });
  const [position, setPosition]: any = useState({
    latitude: null,
    longitude: null,
  });
  const [userUsedGPSPosition, setUserUsedGPSPosition]: any = useState(false);
  const addressList = userPersonalInfo.address ? userPersonalInfo.address : [];
  const [activeAdress, setActiveAdress] = useState('')
  const navigate = useNavigate();
  const userId = userInfo && userInfo.id ? userInfo.id : "";
  const userMobileNumber =
    userInfo && userInfo.phoneNumber ? userInfo.phoneNumber : "";
  const [newAddressDetails, setNewAddressDetails] = useState({
    id: uid(),
    savedAs: "Address" + Number(addressList.length + 1),
    street: "",
    area: "",
    city: "",
    zip: "",
    state: "",
    formatted_address: "",
    phoneNumber: "",
    coordinates: {
      latitude: "",
      longitude: "",
    },
    countryCode:""
  });

  const [updateAddressDetails, setUpdateAddressDetails]: any = useState({});
  const [addressFormErrors, setAddressFormErrors] = useState({
    savedAs: "",
    street: "",
    area: "",
    city: "",
    zip: "",
    state: "",
    formatted_address: "",
    phoneNumber: "",
  });

  useEffect(() => {
    if (userUsedGPSPosition) {
      if (currentGPSAddress) {
        let extractFullCurrentAddress = currentGPSAddress.address;
        setNewAddressDetails({
          ...newAddressDetails,
          ...extractFullCurrentAddress,
        });
      }
    }
  }, [userUsedGPSPosition, currentGPSAddress]);

  const validateAddressFormFields = (addressDetails: any) => {
    const newErrors: any = {};
    if (!addressDetails.savedAs) newErrors.savedAs = ADDRESS_PAGE_LABELS?.addressNameErrorMessage;
    if (!addressDetails.city)
      newErrors.city = ADDRESS_PAGE_LABELS?.cityErrorMessage;
    if (!addressDetails.state) newErrors.state = ADDRESS_PAGE_LABELS?.stateErrorMessage;
    if (!addressDetails.zip) newErrors.zip = ADDRESS_PAGE_LABELS?.zipCodeErrorMessage;
    if (!addressDetails.area) newErrors.area = ADDRESS_PAGE_LABELS?.areaErrorMessage;
    if (
      addressDetails.phoneNumber.length > 0 &&
      !validatePhoneNumber(addressDetails.phoneNumber)
    )
      newErrors.phoneNumber = ADDRESS_PAGE_LABELS?.invalidPhoneNumberMessage;
    setAddressFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const resetFormErrors = () => {
    setAddressFormErrors({
      savedAs: "",
      street: "",
      area: "",
      city: "",
      zip: "",
      state: "",
      formatted_address: "",
      phoneNumber: "",
    });
  };

  const getCurrentPosition = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          dispatch(
            getUserGPSAddressAction(
              position.coords.latitude,
              position.coords.longitude
            )
          );
        },
        (err: any) => {
          console.log(err);
          alert("Please grant location permission");
        }
      );
    } else {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          if (permissionStatus.state === "denied") {
            alert("Please allow location access.");
          } else {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                dispatch(
                  getUserGPSAddressAction(
                    position.coords.latitude,
                    position.coords.longitude
                  )
                );
              },
              (err) => {
                alert("Please grant location permission");
              }
            );
          }
        });
    }
  };

  const saveNewAddress = () => {
    if (!validateAddressFormFields(newAddressDetails)) {
      return;
    }
    let formatAdd = newAddressDetails?.formatted_address;
    if (!newAddressDetails?.formatted_address) {
      formatAdd = getFormattedAddress(newAddressDetails)
    } else {
      formatAdd = newAddressDetails.formatted_address;
    }

    let addressPayload = { ...newAddressDetails, formatted_address: formatAdd };
    if (userId) {
      dispatch(addAddressAction(addressPayload, false, null, null));
      resetFormErrors();
      setCreateNewOrUpdateAddressView({ isCreate: false, isUpdate: false });
    } else {
      console.log("user id not present");
    }
  };

  const saveUpdatedAddress = () => {
    let formatAdd = updateAddressDetails?.formatted_address;
    if (!updateAddressDetails?.formatted_address) {
      formatAdd = getFormattedAddress(updateAddressDetails)
    } else {
      formatAdd = updateAddressDetails.formatted_address;
    }

    let addressPayload = {
      ...updateAddressDetails,
      formatted_address: formatAdd,
    };
    if (!validateAddressFormFields(addressPayload)) {
      return;
    }
    if (userId) {
      dispatch(addAddressAction(addressPayload, true, addressPayload.id, null));
      resetFormErrors();
      setCreateNewOrUpdateAddressView({ isCreate: false, isUpdate: false });
    } else {
      console.log("user id not present");
    }
  };

  const deleteAddress = (addressId: any) => {
    dispatch(deleteUserAddressAction(addressId));
  };

  const cancelAddress = () => {
    setCreateNewOrUpdateAddressView({
      isCreate: false,
      isUpdate: false,
    });

    setNewAddressDetails({
      id: uid(),
      savedAs: "Address" + Number(addressList.length + 1),
      street: "",
      area: "",
      city: "",
      zip: "",
      state: "",
      formatted_address: "",
      phoneNumber: "",
      coordinates: {
        latitude: "",
        longitude: "",
      },
      countryCode:""
    });
    resetFormErrors();
  };

  
  useEffect(() => {
    setActiveAdress(selectedAddressId)
  }, [selectedAddressId])
  

  const handleSelectAddress = (id:any)=>{
      localStorage.setItem("selectedAddressId", id);
      dispatch(setSelectedAddressId(id));
      setActiveAdress(id)
      setTimeout(() => {
        navigate(-1)
      }, 500);
      
  }

  return (
    <div>
      <AddressView
        currentAddress={currentGPSAddress}
        createNewOrUpdateAddressView={createNewOrUpdateAddressView}
        setCreateNewOrUpdateAddressView={setCreateNewOrUpdateAddressView}
        newAddressDetails={newAddressDetails}
        setNewAddressDetails={setNewAddressDetails}
        saveNewAddress={saveNewAddress}
        getCurrentPosition={getCurrentPosition}
        setUpdateAddressDetails={setUpdateAddressDetails}
        updateAddressDetails={updateAddressDetails}
        saveUpdatedAddress={saveUpdatedAddress}
        deleteAddress={deleteAddress}
        addressList={addressList}
        setUserUsedGPSPosition={setUserUsedGPSPosition}
        addressFormErrors={addressFormErrors}
        cancelAddress={cancelAddress}
        resetFormErrors={resetFormErrors}
        setAddressFormErrors={setAddressFormErrors}
        handleSelectAddress={handleSelectAddress}
        activeAdress={activeAdress}
      />
    </div>
  );
}

export default AddressScreen;
