import { createSlice } from "@reduxjs/toolkit";

const priceConversionSlice = createSlice({
  name: "priceconversion",
  initialState: {
    isLoading: false,
    error: null,
    priceConversionRate:null,
    isCountryDisabled : false
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    setError: (state, action) => {
      state.error = action.payload;
    },

    setPriceConversionRate: (state, action) => {
      state.priceConversionRate = action.payload;
    },

    setIsCountryDisabled: (state, action) => {
      state.isCountryDisabled = action.payload;
    },

  },
});

export const {
  setLoading,
  setError,
  setPriceConversionRate,
  setIsCountryDisabled
} = priceConversionSlice.actions;

export default priceConversionSlice.reducer;
