import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import AIGurujiModal from "./AIGurujiModal/AIGurujiModal";
import AIGurujiChatBackground from "./AIGurujiChatBackground/AIGurujiChatBackground";
import AIGurujiBirthChart from "./AIGurujiChatItem/AIGurujiBirthChart/AIGurujiBirthChart";
import AIGurujiCalculationChart from "./AIGurujiChatItem/AIGurujiCalculationChart/AIGurujiCalculationChart";
import AIGurujinumerologicalpredictions from "./AIGurujiChatItem/AIGurujinumerologicalpredictions/AIGurujinumerologicalpredictions";
import AIGurujinumerologic from "./AIGurujiChatItem/AIGurujinumerologic/AIGurujinumerologic";
import AIGurujiBirthChartForm from "./AIGurujiChatItem/AIGurujiBirthChartForm/AIGurujiBirthChartForm";
import "./AIGurujiView.scss";
import AIGurujiValmikiImg from "./AIGurujiChatItem/AIGurujiValmikiImg/AIGurujiValmikiImg";
import AIGurujiReligiousChat from "./AIGurujiChatItem/AIGurujiReligiousChat/AIGurujiReligiousChat";
import AIGurujitext from "./AIGurujiChatItem/AIGurujitext/AIGurujitext";
import AIGurujitextSender from "./AIGurujiChatItem/AIGurujitextSender/AIGurujitextSender";
import AIGurujiReportStringToTable from "./AIGurujiChatItem/AIGurujiReportStringToTable/AIGurujiReportStringToTable";
import AIGurujiError from "./AIGurujiChatItem/AIGurujiError/AIGurujiError";
import AIGurujiReligiousRowChat from "./AIGurujiChatItem/AIGurujiReligiousChat/AIGurujiReligiousRowChat";
import AIGurujiRashiCard from "./AIGurujiChatItem/AIGurujiRashiCard/AIGurujiRashiCard";
import AIGurujiPrediction from "./AIGurujiChatItem/AIGurujiPrediction/AIGurujiPrediction";
import AIGurujiExportButton from "./AIGurujiChatItem/AIGurujiExportButton/AIGurujiExportButton";
import AIGurujiServiceStartText from "./AIGurujiChatItem/AIGurujiServiceStartText/AIGurujiServiceStartText";
import AIGurujiKundliMatchingForm from "./AIGurujiChatItem/AIGurujiKundliMatchingForm/AIGurujiKundliMatchingForm";
import AIGurujiKundliCardInfo from "./AIGurujiChatItem/AIGurujiKundliCardInfo/AIGurujiKundliCardInfo";
import { TableName } from "../../utils/ConstantsMessages";
import AIGurujiLanguageSelect from "./AIGurujiChatItem/AIGurujiLanguageSelect/AIGurujiLanguageSelect";

interface AIGurujiView {
  open: boolean;
  closeModal: () => void;
  onLanguageSwitch: any;
  chatMessage: any[];
  onMenuSelect: any;
  sendInpute: any;
  isLoading: boolean;
  isChatInputBoxDisebal: boolean;
  transcribeAudio: any;
  calculateNumerology: any;
  calculateHoroscope: any;
  selectedLang: string;
  isPlayingAudioId: any;
  handleBackToMainMenu: any;
  handleEditForm: any;
  availableButton: any;
  selectedServices: any;
  isPlaying: boolean;
  handleDownload: any;
  isDownloading: boolean;
  exportChatId: any;
  isMobileView: boolean;
  calculateMatchMaker: any;
}

const AINumerology: React.FC<any> = ({
  chat,
  handleScrollTobirthChartForm,
}) => {
  const data = chat?.formateResponse || null;
  return (
    <>
      {data && (
        <>
          <AIGurujinumerologicalpredictions
            responseText={data.response_text}
            numberCalculations={data.number_calculations}
          />

          {data.cards_section?.map((item: any, index: number) => (
            <AIGurujinumerologic key={index} cardsSection={item} />
          ))}
          <AIGurujiCalculationChart
            currentPayload={chat?.currentPayload}
            id={chat.id}
            handleScrollTobirthChartForm={handleScrollTobirthChartForm}
          />
        </>
      )}
    </>
  );
};

const AIGurujiKundli: React.FC<any> = ({
  data,
  handleScrollTobirthChartForm,
}) => {
  return (
    <>
      {data && data?.kundli_chart_blob_url && (
        <AIGurujiBirthChart
          birthChartUrl={data?.kundli_chart_blob_url}
          text="Here is the combined birth chart based on your input,
            displaying the planetary positions at the time of birth."
        />
      )}
      {data?.formateResponse &&
        data?.formateResponse?.ascendant_compatibility && (
          <AIGurujiKundliCardInfo
            data={data?.formateResponse?.ascendant_compatibility}
            imageurl={
              data?.cards_section_icon?.ascendant_compatibility_svg || null
            }
          />
        )}
      {data?.formateResponse && data?.formateResponse?.ashta_koota_matching && (
        <AIGurujiKundliCardInfo
          data={data?.formateResponse?.ashta_koota_matching}
          imageurl={data?.cards_section_icon?.ashta_koota_matching_svg || null}
        />
      )}
      {data?.formateResponse && data?.formateResponse?.children_and_progeny && (
        <AIGurujiKundliCardInfo
          data={data?.formateResponse?.children_and_progeny}
          imageurl={data?.cards_section_icon?.children_and_progeny_svg || null}
        />
      )}
      {data?.formateResponse && data?.formateResponse?.manglik_dosha && (
        <AIGurujiKundliCardInfo
          data={data?.formateResponse?.manglik_dosha}
          imageurl={data?.cards_section_icon?.manglik_dosha_svg || null}
        />
      )}

      {data?.formateResponse && data?.formateResponse?.planetary_positions && (
        <AIGurujiKundliCardInfo
          data={data?.formateResponse?.planetary_positions}
          imageurl={data?.cards_section_icon?.planetary_positions_svg || null}
        />
      )}
      {data?.formateResponse &&
        data?.formateResponse?.moon_sign_compatibility && (
          <AIGurujiKundliCardInfo
            data={data?.formateResponse?.moon_sign_compatibility}
            imageurl={
              data?.cards_section_icon?.moon_sign_compatibility_svg || null
            }
          />
        )}
      {data?.formateResponse &&
        data?.formateResponse?.match_making_compatibility_percentage && (
          <AIGurujiKundliCardInfo
            data={data?.formateResponse?.match_making_compatibility_percentage}
            imageurl={
              data?.cards_section_icon
                ?.match_making_compatibility_percentage_svg || null
            }
          />
        )}
      {data?.formateResponse &&
        data?.formateResponse?.venus_jupiter_analysis && (
          <AIGurujiKundliCardInfo
            data={data?.formateResponse?.venus_jupiter_analysis}
            imageurl={
              data?.cards_section_icon?.venus_jupiter_analysis_svg || null
            }
          />
        )}
      {data?.formateResponse && data?.formateResponse?.seventh_house && (
        <AIGurujiKundliCardInfo
          data={data?.formateResponse?.seventh_house}
          imageurl={data?.cards_section_icon?.seventh_house_svg || null}
        />
      )}
      {data?.formateResponse &&
        data?.formateResponse?.nakshatra_compatibility && (
          <AIGurujiKundliCardInfo
            data={data?.formateResponse?.nakshatra_compatibility}
            imageurl={
              data?.cards_section_icon?.nakshatra_compatibility_svg || null
            }
          />
        )}
      {data?.formateResponse &&
        data?.formateResponse?.financial_and_career_stability && (
          <AIGurujiKundliCardInfo
            data={data?.formateResponse?.financial_and_career_stability}
            imageurl={
              data?.cards_section_icon?.financial_and_career_stability_svg ||
              null
            }
          />
        )}
      {data?.formateResponse && data?.formateResponse?.timing_of_marriage && (
        <AIGurujiKundliCardInfo
          data={data?.formateResponse?.timing_of_marriage}
          imageurl={data?.cards_section_icon?.timing_of_marriage_svg || null}
        />
      )}

      {data?.formateResponse &&
        data?.formateResponse?.suggested_remedies_and_gemstones && (
          <AIGurujiKundliCardInfo
            data={data?.formateResponse?.suggested_remedies_and_gemstones}
            imageurl={
              data?.cards_section_icon?.suggested_remedies_and_gemstones_svg ||
              null
            }
          />
        )}
      <AIGurujiCalculationChart
        currentPayload={data?.currentPayload.first_person_details}
        id={data.id}
        handleScrollTobirthChartForm={handleScrollTobirthChartForm}
        nameTitle={TableName.girlNameTitle}
      />

      <AIGurujiCalculationChart
        currentPayload={data?.currentPayload.second_person_details}
        id={data.id}
        handleScrollTobirthChartForm={handleScrollTobirthChartForm}
        nameTitle={TableName.boyNameTitle}
        isTitleEnabled={false}
      />
    </>
  );
};

const AIHoroscope: React.FC<any> = ({ data, handleScrollTobirthChartForm }) => {
  return (
    <>
      <AIGurujiBirthChart
        birthChartUrl={data?.birth_chart_url}
        text="Birth Chart"
      />
      <AIGurujiBirthChart
        birthChartUrl={data?.lagna_chart_url}
        text="Lagan Chart"
      />
      <AIGurujiRashiCard sunSign={data?.sun_sign} moonSign={data?.moon_sign} />

      {data?.final_table_report &&
        data?.final_table_report.map((item: any) => (
          <AIGurujiReportStringToTable
            tableName={item?.name}
            reportString={item?.tableValue}
          />
        ))}

      <AIGurujiCalculationChart
        currentPayload={data?.currentPayload}
        id={data.id}
        handleScrollTobirthChartForm={handleScrollTobirthChartForm}
      />
      <AIGurujiPrediction predictionText={data?.response} />
    </>
  );
};

const AIGurujiView: React.FC<AIGurujiView> = ({
  open,
  closeModal,
  onLanguageSwitch,
  chatMessage,
  onMenuSelect,
  sendInpute,
  isLoading,
  transcribeAudio,
  isChatInputBoxDisebal,
  calculateNumerology,
  calculateHoroscope,
  selectedLang,
  isPlayingAudioId,
  handleBackToMainMenu,
  handleEditForm,
  availableButton,
  selectedServices,
  isPlaying,
  handleDownload,
  isDownloading,
  exportChatId,
  isMobileView,
  calculateMatchMaker,
}) => {
  const birthChartFormRef = useRef<HTMLDivElement | null>(null);

  const handleScrollTobirthChartForm = (id: any) => {
    handleEditForm(id);
  };
  const boxRef = useRef<any>(null);
  const [matchedExport, setMatchedExport] = useState<any>(null);

  useEffect(() => {
    scrollToLastItem();

    if (chatMessage && chatMessage.length > 3) {
      let updatedExport: any = matchedExport || {};

      const newExport = chatMessage.reduce(
        (acc: any, _chatItem: any, _index: number) => {
          if (_chatItem?.service && _chatItem?.type === "receive") {
            const service = _chatItem?.service;
            if (!acc[service]) {
              acc[service] = { start: _index, end: -1 };
            } else {
              acc[service].end = _index;
            }
          }
          return acc;
        },
        {}
      );
      if (JSON.stringify(updatedExport) !== JSON.stringify(newExport)) {
        setMatchedExport(newExport);
      }
    }
  }, [chatMessage]);

  useEffect(() => {
    if (matchedExport) {
    }
  }, [matchedExport]);

  const scrollToLastItem = () => {
    if (boxRef.current) {
      const children = boxRef.current.children;

      if (children.length > 0) {
        const lastItem = children[children.length - 1];
        const lastItemRect = lastItem.getBoundingClientRect();
        const boxRect = boxRef.current.getBoundingClientRect();
        lastItem.scrollIntoView({ behavior: "smooth", block: "start" });
        const offset = lastItemRect.height - boxRect.height;
        if (offset > 0) {
          boxRef.current.scrollBy({
            top: offset,
            behavior: "smooth",
          });
        }
      }
    }
  };

  return (
    <AIGurujiModal openDialog={open}>
      <AIGurujiChatBackground
        setHandleClose={closeModal}
        sendInpute={sendInpute}
        isLoading={isLoading}
        isChatInputBoxDisebal={isChatInputBoxDisebal}
        selectedLang={selectedLang}
        handleLanguageSwitch={onLanguageSwitch}
        chatMessageLength={chatMessage.length}
        handleBackToMainMenu={handleBackToMainMenu}
        lastChatItem={chatMessage[chatMessage.length - 1]}
        handleDownload={handleDownload}
        isMobileView={isMobileView}
      >
        <Box className="chatBackgroundInfo">
          <Box className="valmikiImage">
            <AIGurujiValmikiImg />
          </Box>
          <Box>
            {chatMessage.length === 0 && (
              <AIGurujiLanguageSelect
                onLanguageSwitch={onLanguageSwitch}
                lang={selectedLang}
              />
            )}
            {chatMessage.length === 1 && (
              <AIGurujiServiceStartText selectedLang={selectedLang} />
            )}
          </Box>
          <div ref={boxRef}>
            {chatMessage &&
              chatMessage?.map((chat: any, index = 0) => {
                const isDisabled = chatMessage.length !== index + 1;
                return (
                  <>
                    {chat?.available_actions &&
                      chat?.available_actions?.length && (
                        <AIGurujiReligiousChat
                          onMenuSelect={onMenuSelect}
                          availableActions={chat.available_actions}
                          key={`AIGurujiReligiousChat ${index}`}
                          isDisabled={isDisabled}
                          direction="coloum"
                          selectedServices={selectedServices}
                        />
                      )}
                    {chat?.contentType === "text" &&
                      chat?.type === "receive" &&
                      chat?.response && (
                        <AIGurujitext
                          text={chat?.response}
                          transcribeAudio={transcribeAudio}
                          time={chat?.currentTime}
                          id={chat?.id}
                          isPlayingAudioId={isPlayingAudioId}
                          isPlaying={isPlaying}
                        />
                      )}
                    {chat?.contentType === "error" &&
                      chat?.type === "receive" &&
                      chat?.response && (
                        <AIGurujiError
                          text={chat?.response}
                          transcribeAudio={transcribeAudio}
                          time={chat?.currentTime}
                          id={chat?.id}
                          isPlayingAudioId={isPlayingAudioId}
                          isPlaying={isPlaying}
                        />
                      )}
                    {chat?.contentType === "text" &&
                      chat?.type === "send" &&
                      chat?.response && (
                        <AIGurujitextSender
                          text={chat?.response}
                          time={chat?.currentTime}
                        />
                      )}
                    {chat?.contentType === "menu" &&
                      chat?.service === "ai_numerology" && (
                        <AIGurujiBirthChartForm
                          buttonName="Numerology"
                          handleSubmitForm={calculateNumerology}
                          ref={birthChartFormRef}
                          isDisabled={isDisabled}
                          selectedLang={selectedLang}
                          formFilledData={
                            chat?.formData ? chat?.formData : null
                          }
                        />
                      )}

                    {chat?.contentType === "json" &&
                      chat?.service === "ai_numerology" && (
                        <AINumerology
                          chat={chat}
                          handleScrollTobirthChartForm={
                            handleScrollTobirthChartForm
                          }
                        />
                      )}

                    {chat?.contentType === "menu" &&
                      chat?.service === "ai_horoscope" && (
                        <AIGurujiBirthChartForm
                          buttonName="Horoscope"
                          handleSubmitForm={calculateHoroscope}
                          ref={birthChartFormRef}
                          isDisabled={isDisabled}
                          selectedLang={selectedLang}
                          formFilledData={
                            chat?.formData ? chat?.formData : null
                          }
                        />
                      )}

                    {chat?.contentType === "json" &&
                      chat?.service === "ai_horoscope" && (
                        <AIHoroscope
                          data={chat}
                          handleScrollTobirthChartForm={
                            handleScrollTobirthChartForm
                          }
                        />
                      )}

                    {chat?.contentType === "menu" &&
                      chat?.service === "ai_match_maker" && (
                        <AIGurujiKundliMatchingForm
                          buttonName="Match Kundli"
                          handleSubmitForm={calculateMatchMaker}
                          ref={birthChartFormRef}
                          isDisabled={isDisabled}
                          selectedLang={selectedLang}
                          formFilledData={
                            chat?.formData ? chat?.formData : null
                          }
                        />
                      )}

                    {chat?.contentType === "json" &&
                      chat?.service === "ai_match_maker" && (
                        <AIGurujiKundli
                          data={chat}
                          handleScrollTobirthChartForm={
                            handleScrollTobirthChartForm
                          }
                        />
                      )}

                    {chat.service &&
                      matchedExport &&
                      matchedExport[chat.service] &&
                      matchedExport[chat.service].end === index && (
                        <AIGurujiExportButton
                          handleDownload={() =>
                            handleDownload(chat, matchedExport)
                          }
                          isDownloading={
                            (isDownloading && exportChatId === chat?.id) ||
                            false
                          }
                        />
                      )}
                  </>
                );
              })}
          </div>
          {availableButton && availableButton?.length > 0 && (
            <AIGurujiReligiousRowChat
              onMenuSelect={onMenuSelect}
              availableActions={availableButton}
              key={`AIGurujiReligiousChat`}
              isDisabled={false}
              direction="row"
              selectedServices={selectedServices}
            />
          )}
        </Box>
      </AIGurujiChatBackground>
    </AIGurujiModal>
  );
};

export default AIGurujiView;
