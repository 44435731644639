import React from "react";
import { Stack, Box, Typography, Link } from "@mui/material";
import quarkgenLogo from "../../../../assets/poweredby-quarkgen.png";
import "./AIGurujiPoweredByAdd.scss";

function AIGurujiPoweredByAdd() {
  return (
    <Stack className="poweredByText" direction="row" spacing={1}>
      <Typography>
        Powered by{" "}
        <Link
          href="https://quarkgen.ai/"
          target="_blank"
        >
          QuarkGen AI
        </Link>
      </Typography>
      <Box component="img" src={quarkgenLogo} alt="QuarkGen Logo" />
    </Stack>
  );
}

export default AIGurujiPoweredByAdd;
