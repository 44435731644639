import { Box, Typography } from "@mui/material";
import React from "react";
import "./NoDataFound.scss";

const NoDataFound = () => {
  return (
    <Box className="noDataFound">
      <Typography className="noDataFoundText">No data found.</Typography>
    </Box>
  );
};

export default NoDataFound;
