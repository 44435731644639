import {
  Box,
  Button,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Badge,
  Radio,
} from "@mui/material";


import { useEffect, useState } from "react";
import { getPriceInfoOfPujaAndPackage } from "../../../store/actions/templeAction";
import { useDispatch, useSelector } from "react-redux";
import { calculateOverSeasPrice } from "../../../utils/HelperFunctions";

//
// ,overSeasRate
// currencySymbols,
// isOverseas,
// isCountryDisabledForBooking
export default function PujaPackageCard(props: any) {
  const dispatch: any = useDispatch();
  const [pujaPriceInfo, setPujaPriceInfo]: any = useState(null)
  var { currentGPSAddress } = useSelector((state: any) => state.user);
  useEffect(() => {

    if (props.packageDetails, props.selectedTempleDetails, props.pujaDetails) {
      getPriceInfo(props.selectedTempleDetails.id, props.pujaDetails.pujaId, props.packageDetails.packageId)
    }

  }, [props.packageDetails, props.selectedTempleDetails, props.pujaDetails,currentGPSAddress])

 


  const getPriceInfo = async (templeId: any, pujaId: any, packageId: any) => {
    let payload = {
      templeId: templeId,
      pujaId: pujaId,
      packageId: packageId,
      isOverseas: props.isOverseas,
      isPrasad: !props.isOverseas
    };
    console.log("Payload", payload);
    try {
      dispatch(getPriceInfoOfPujaAndPackage(payload))
        .then((response: any) => {
          console.log("Puja price info response", response)
          setPujaPriceInfo(response)
        })
        .catch((error: any) => {
          console.log("Error in puja price fetch", error)
        })
      // console.log("Fetch function screen price res", responseData);
    }

    catch (error) {
      console.log("Error fetching price inf")
    }
  }

  return (
    <Box
      className={`packageCard ${props.activeFlg}`}
      onClick={() => props.setPackageSelected(props.packageDetails)}
    >
      <img src={props.getDevoteeImage(props.packageDetails?.maxMemberAllowed)} alt="" />
      <Typography component="p">
        {props.packageDetails?.maxMemberAllowed} Devotee
      </Typography>
      <Typography variant="h6">{props.currencySymbols}{calculateOverSeasPrice(pujaPriceInfo?.finalPrice, props.overSeasRate)}</Typography>
      <Radio
        checked={props.activeFlg === "active"}
        onChange={props?.handleChange}
        value="option1"
      />
      {props.packageDetails?.isMostPopular ? (
        <Badge className="nwlyAddedbadge">Most Popular</Badge>
      ) : null}
    </Box>
  );
}
