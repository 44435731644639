import moment from "moment";
import {
  MODIFICATION_TYPE_LIST,
  orderType,
  PUJA_ORDER_STATUS,
} from "../../utils/Constants";
import {
  setLoading,
  setOrderList,
  setUserOrderList,
  setUserDonationList,
} from "./../slices/orderSlice";

import { OrderService } from "td_server";
import {
  getDateNowInUtc,
  getDateNowInUtcWithFormate,
} from "../../utils/HelperFunctions";
import { emptyCartAction } from "./cartAction";
import { platform } from "os";
// import {format} from 'date-fns';
// import {generateUniqueNumberString} from '../../utils/functions';
// import {MODIFICATION_TYPE_LIST, PUJA_ORDER_STATUS} from '../../constants';
export const placeOrderAction =
  (
    cartInfo: any,
    userInfo: any,
    address: any,
    finalPrice: any,
    transectionId: any,
    orderId: any,
    razorpayPaymentId: any,
    priceBreakup: any,
    isPrasad:boolean = false,
    isOverseas:boolean = false,
    rate=1,
    currencySymbols='₹',
    currencyCode="INR",
    paymentMethod: any = "CARD"
  ) =>
  async (dispatch: any) => {
    // console.log("Puja date selected",`${cartInfo[0].pujaDateTime.pujaDate}`)
    try {
      dispatch(setLoading(true));
      console.log("-----cartInfo-------", cartInfo);
      const orderData = {
        orderId: orderId,
        pujaId: cartInfo[0]?.pujaDetails
          ? cartInfo[0]?.pujaDetails?.pujaId
          : "",
        pujaName: cartInfo[0]?.pujaDetails
          ? cartInfo[0]?.pujaDetails?.pujaName
          : "",
        templeId: cartInfo[0]?.templeDetails?.id,
        pujaDate: `${cartInfo[0]?.pujaDateTime?.pujaDate}`,
        pujaDetails: cartInfo[0]?.pujaDetails || null,
        pujaPackage: cartInfo[0]?.pujaPackage,
        whatsAppNumber: cartInfo[0]?.whatsAppNumber,
        deviceType: "WEB",
        orderType: cartInfo[0]?.orderType,
        chadhavaList: cartInfo[0]?.selectedChadhvaList || [],
        templeDetails: {
          address: cartInfo[0].templeDetails.personalInfo.address,
          name: cartInfo[0].templeDetails.personalInfo.name,
          phoneNumber: cartInfo[0].templeDetails.personalInfo.phoneNumber,
          admin: cartInfo[0].templeDetails.selectedAdminList,
        },
        orderUserDetails: {
          orderUserName: `${userInfo.personalInfo.firstName} ${userInfo.personalInfo.lastName}`,
          orderUserId: userInfo.id,
          phoneNumber: userInfo.phoneNumber,
          email: userInfo.personalInfo.email,
          numberOfHeadCount: cartInfo[0].members.length,
          gender: userInfo.personalInfo.gender,
          groupDetails: cartInfo[0].members,
        },
        deliveryAddress: address,
        orderData: {
          isPrasad,
          isOverseas,
          rate,
          currencySymbols,
          currencyCode,
          priceBreakup: priceBreakup,
          orderDateTime: getDateNowInUtc(),
          payment: {
            orderValue: finalPrice,
            paymentMode: "CARD",
            paymentStatus: "success",
            paymentDateTime: getDateNowInUtc(),
            transactionId: transectionId,
            razorpayPaymentId: razorpayPaymentId,
            paymentId: paymentMethod,
          },
          multimediaLink: "",
        },
        orderStatus: [
          {
            orderStatus: PUJA_ORDER_STATUS.NEW,
            dateTime: getDateNowInUtc(),
            modifiedBy: {
              phoneNumber: userInfo.phoneNumber,
              isUser: true,
              name: `${userInfo.personalInfo.firstName} ${userInfo.personalInfo.lastName}`,
            },
          },
        ],
      };

      console.log("ORDER data===", orderData);
      const orderResponse = await OrderService.createOrder(orderData);
      console.log("Order RESPONSE --->", orderResponse);
      dispatch(setOrderList([]));
      dispatch(emptyCartAction());
      dispatch(setLoading(false));
      return Promise.resolve(orderId);
    } catch (error: any) {
      console.log("ORDER ERROR : ", error);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const addDonationInfoAction =
  (formData: any, templeDetails: any, userInfo: any, transectionId: any) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const data = {
        ...formData,
        templeId: templeDetails.id,
        userId: userInfo.id,
        transectionId: transectionId,
        paymentStatus: "pending",
        templeDetails: {
          address: templeDetails.personalInfo.address,
          name: templeDetails.personalInfo.name,
          phoneNumber: templeDetails.personalInfo.phoneNumber,
        },
      };
      const orderId = await OrderService.addDonationInformation(data);
      dispatch(setLoading(false));
      return Promise.resolve(orderId);
    } catch (error: any) {
      console.log("ORDER ERROR : ", error);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getUserOrder = (userInfo: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const userOrderList = await OrderService.getOrderOfUser(userInfo.id);
    dispatch(setLoading(false));
    dispatch(setUserOrderList(userOrderList));

    return Promise.resolve(userOrderList);
  } catch (error: any) {
    console.log("getUserOrder ERROR : ", error);
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};

export const getAllOrder =
  (pageSize: any, pageNumber: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      console.log("getAllOrder ACtion");
      const userOrderList = await OrderService.getAllOrders(1000, 10);
      console.log("getAllOrder ACtion", userOrderList);
      dispatch(setLoading(false));
      dispatch(setUserOrderList(userOrderList));

      return Promise.resolve(userOrderList);
    } catch (error: any) {
      console.log("getUserOrder ERROR : ", error);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getUserDonation = (userInfo: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const userDonationList = await OrderService.getDonationOfUser(userInfo.id);
    dispatch(setLoading(false));
    dispatch(setUserDonationList(userDonationList));
    const userOrderList = await OrderService.getAllOrders(0, 100);
    dispatch(setLoading(false));
    dispatch(setUserOrderList(userOrderList));
    return Promise.resolve(userDonationList);
  } catch (error: any) {
    console.log("getUserDonation ERROR : ", error);
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};

export const updateOrderStatusAction =
  (formData: any, orderId: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      if (formData.modificationType === MODIFICATION_TYPE_LIST[0]) {
        formData.orderStatus = formData.pujaStatus;
      }
      if (formData.modificationType === MODIFICATION_TYPE_LIST[1]) {
        formData.orderStatus = formData.deliveryStatus;
      }
      const status = await OrderService.updateSingleOrderStatus(
        formData,
        orderId
      );
      dispatch(setLoading(false));
      return Promise.resolve(status);
    } catch (error: any) {
      console.log("updateUserStatus ERROR : ", error);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };
