import { useEffect, useState } from "react";
import downarrow from "../../../assets/down-arrow.png";
import RoundedButton from "../../button/RoundedButton";
import {
  Box,
  Button,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Badge,
} from "@mui/material";
import TextArea from "../../textarea/Textarea";
import templeIcon from "../../../assets/temple-icon.png";
import locationIcon from "../../../assets/loc-icon.png";
import pujaNameIcon from "../../../assets/puja-name-icon.png";
import devoteeIcon from "../../../assets/devotee-icon.png";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import "./PujaListCard.scss";

export default function PujaListCard(props: any) {

  const { pujaData, benefitList, temple, templeProfilePic, pujaName, onClickAddPuja, isPujaDisable, isPromotionalPuja = false} = props;
  const [isPujaOverviewSelected, setIsPujaOverviewSelected] = useState(true);


  const getMaxAllowedMember = () => {
    let pujaDetails: any;
    if (isPromotionalPuja) {
      pujaDetails = pujaData;
    } else {
      pujaDetails = pujaData?.pujaDetails;
    }

    return pujaDetails?.multiplePujaDetails &&
      pujaDetails?.multiplePujaDetails?.length > 0
      ? pujaDetails?.multiplePujaDetails[
          pujaDetails?.multiplePujaDetails?.length - 1
        ].maxMemberAllowed
      : pujaDetails?.maxMemberAllowed || 1;
  };
  const [bnftText, setBnftText] = useState("");
  useEffect(() => {
    let benifitText = "";
    if (benefitList?.length > 0) {
      benefitList.map((item: any, index = 0) => {
        benifitText += `${index !== 0 ? "|" : ""}  ${item.name} `;
      });
      setBnftText(benifitText);
    }
  }, [benefitList]);

  const pujaAddress =
    (temple?.address?.street ||
      temple?.address?.city ||
      temple?.address?.area) +
    " , " +
    temple?.address?.state;

  return (
    <Box className="pujaAreaCard">
      <Box className="pujaImg">
        <img src={templeProfilePic} alt="" />
        <Badge className="devoteeBadge">
          <Box className="badgeIcon">
            <img src={devoteeIcon} alt="" />
          </Box>
          <Box className="badgeText">
            <Typography>{`Upto ${getMaxAllowedMember()} devotees`}</Typography>
          </Box>
        </Badge>
      </Box>

      <Box className="card-body">
        <Box className="cardTitle">
          <Typography className="mainPujaTitle">{pujaName}</Typography>
          <Typography className="subTitle">{bnftText}</Typography>
        </Box>

        <Box className="pujaDescription">
          <List>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: "50px" }}>
                <Avatar alt="Temple Icon" className="squreShape temple">
                  <img src={templeIcon}></img>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={temple?.name} />
            </ListItem>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: "50px" }}>
                <Avatar alt="Location Icon" className="squreShape location">
                  <img src={locationIcon}></img>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={pujaAddress} />
            </ListItem>
          </List>
        </Box>
        <Button
          variant="contained"
          endIcon={<KeyboardDoubleArrowRightIcon />}
          className="cardLastBtn"
          onClick={() => onClickAddPuja(pujaData)}
          disabled={isPujaDisable}
        >
          Participate Now
        </Button>
      </Box>
    </Box>
  );
}
