import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

import { UserService, CouponService } from "td_server";

import { env } from "../../../env";
import ParentView from "../../../Views/PrarentView/ParentView";
import CouponCodeView from "../../../Views/Admin/CouponCode/CouponCodeView";
import {
  updateCouponCodeSliceAction,
  getCouponCodeList,
} from "../../../store/actions/CouponCodeAction";
import { setLoading } from "../../../store/slices/couponCodeSlice";
import { useDispatch, useSelector } from "react-redux";
import CouponCodeList from "../../../Views/Admin/CouponCode/CouponCodeList";
import { getDateNowInUtc } from "../../../utils/HelperFunctions";
import moment from "moment";
function CouponCodeScreen() {
  const dispatch: any = useDispatch();
  const { couponcodeList } = useSelector((state: any) => state.couponcode);
  const isLoading = useSelector((state: any) => state.couponcode.isLoading);
  const [singlecouponcode, setSingleCouponcode] = useState<any>(null);
  const [codeList, setCodeList] = useState<any>([]);
  const { userInfo } = useSelector((state: any) => state.user);
  const [mode, setMode] = useState<string>("list");
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const { allTempleList } = useSelector((state: any) => state.temple);
  const [templeList, setTempleList] = useState<any>([]);
  const [allPujaList, setAllPujaList] = useState<any>([]);
  const [allPackagesList, setAllPackagesList] = useState<any>([]);
  const [selectedPackageIds, setSelectedPackageIds] = useState<any>([]);

  const [seletedTempleOptions, setSeletedTempleOptions] = useState<any>([]);
  const [seletedPujaOptions, setSeletedPujaOptions] = useState<any>([]);
  const [seletedPackageOptions, setSeletedPackageOptions] = useState<any>([]);

  useEffect(() => {
    dispatch(getCouponCodeList());
  }, [dispatch]);

  useEffect(() => {
    setCodeList(couponcodeList);
  }, [couponcodeList]);

  useEffect(() => {
    if (allTempleList && allTempleList.length) {
      let formatedList = allTempleList?.map((item: any) => {
        return { value: item.id, label: item?.personalInfo?.name };
      });
      formatedList=[{ value: "all", label: "All" },...formatedList];
      setTempleList(formatedList);
    }
  }, [allTempleList]);


  const handleSubmit = async (data: any) => {
    setMode("list");
    const couponLog: any = data?.couponLog;
    const newCouponLog: any = [
      ...couponLog,
      {
        userId: userInfo.id,
        username:
          userInfo?.personalInfo?.firstName +
          " " +
          userInfo?.personalInfo?.lastName,
        timestamp: getDateNowInUtc(),
      },
    ];
    const newData = {
      ...data,
      couponLog: newCouponLog,
      packageIds: selectedPackageIds,
    };

    // return
    dispatch(updateCouponCodeSliceAction(newData));
  };
  const handleModeChange = (mode: string) => {
    setMode(mode);
  };
  const handleAdd = () => {
    setSingleCouponcode(null);
    setMode("add");
    setSeletedTempleOptions(null);
    setSeletedPujaOptions(null);
    setSeletedPackageOptions(null);

  };
  const handleEdit = (row: any) => {
    setMode("edit");
    setSingleCouponcode(row);
    let selectedTempleIds: string[] = [];
    let selectedPujaIds: string[] = [];
    let selectedPakageIds: string[] = [];
    if (row?.packageIds?.length > 0) {
      row?.packageIds?.forEach((el: any) => {
        if (el?.length) {
          const item = el.split("_");
          selectedTempleIds.push(item[0]);
          selectedPujaIds.push(item[0] + "_" + item[1]);
          selectedPakageIds.push(el);
        }
      });
    }
   
    setSeletedTempleOptions(selectedTempleIds);
    setSeletedPujaOptions(selectedPujaIds);
    setSeletedPackageOptions(selectedPakageIds);

    //----------------------------------------------------------------
    let pujas: { value: string; label: string }[] = [];
    let packages: { value: string; label: string }[] = [];
    const selectedTemples =
      allTempleList &&
      allTempleList.filter((item: any) => selectedTempleIds.includes(item.id));
    selectedTemples?.forEach((templs: any) => {
      templs.templePuja.forEach((puja: any) => {
        pujas.push({
          value: `${templs.id}_${puja.pujaId}`,
          label: `${templs?.personalInfo?.name}-${puja?.pujaName}`,
        });
        //----------------------Package------------------------------------------
        if (selectedPujaIds.includes(`${templs.id}_${puja.pujaId}`)) {


          packages.push({
            value: `${templs?.id}_${puja?.pujaId}_all`,
            label: `${templs?.personalInfo?.name}-${puja?.pujaName}-ALL`,
          });
          
          if (puja?.multiplePujaDetails && puja?.multiplePujaDetails.length) {
            puja?.multiplePujaDetails?.forEach((pack: any) => {
              
              packages.push({
                value: `${templs.id}_${puja.pujaId}_${pack?.packageId}`,
                label: `${templs?.personalInfo?.name}-${puja?.pujaName}-${pack?.maxMemberAllowed} Devotee`,
              });
            });
          }
        }
        //----------------------------------------------------------------------------
      });
    });
    setAllPujaList(pujas);
    setAllPackagesList(packages);
    //----------------------------------
  };
  const handleBack = () => {
    setMode("list");
  };
  // const handleDelete = (row:any)=>{

  //    const newData = {...row,isActive:false}
  //    dispatch(updateCouponCodeSliceAction(newData));
  //    handleClose();
  // }

  const handleDelete = () => {
    const data: any = selectedRow;
    const validDate: any = moment()
      .utc()
      .set({ hours: 0, minutes: 0, seconds: 0 })
      .subtract(1, "days")
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
    const newData: any = { ...data, isActive: false, validTill: validDate };
    dispatch(updateCouponCodeSliceAction(newData));
    handleClose();
  };

  const handleClickOpen = (row: any) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleTempleSelect = (event: any) => {
    let pujas: { value: string; label: string }[] = [];
    const selectedTempleIds = event?.map((item: any) => item.value);
    const selectedTemples = allTempleList.filter((item: any) =>
      selectedTempleIds.includes(item.id)
    );
    selectedTemples?.forEach((templs: any) => {
      templs.templePuja.forEach((puja: any) => {
        pujas.push({
          value: `${templs.id}_${puja.pujaId}`,
          label: `${templs?.personalInfo?.name}-${puja?.pujaName}`,
        });
      });
    });
    setAllPujaList(pujas);

    // All select case
    if(event&&event.length&&event[0].value==="all"){
      setSelectedPackageIds(["all_all_all"]);
    }
    
  };

  const handlePujaSelect = (event: any) => {
    let packages: { value: string; label: string }[] = [];
    const templeIds: string[] = [];
    const pujaIds: string[] = [];
    const templePujaId: string[] = [];
    const selectedTempleIds = event?.forEach((item: any) => {
      const ids = item?.value?.split("_");
      templeIds.push(ids[0]);
      pujaIds.push(ids[1]);
      templePujaId.push(item?.value);
    });
    const selectedTemples = allTempleList.filter((item: any) =>
      templeIds.includes(item.id)
    );
    selectedTemples.forEach((temple: any) => {
      const templeName = temple?.personalInfo?.name;
      const templeId = temple?.id;
      temple?.templePuja?.forEach((puja: any) => {
        if (templePujaId.includes(`${templeId}_${puja.pujaId}`)) {
          if (puja?.multiplePujaDetails && puja?.multiplePujaDetails.length) {
            puja?.multiplePujaDetails?.forEach((pack: any) => {
              packages.push({
                value: `${templeId}_${puja.pujaId}_${pack?.packageId}`,
                label: `${templeName}-${puja?.pujaName}-${pack?.maxMemberAllowed} Devotee`,
              });
            });
          }
          packages.push({
            value: `${templeId}_${puja.pujaId}_all`,
            label: `${templeName}-${puja?.pujaName}-ALL`,
          });
        }
      });
    });
    setAllPackagesList(packages);
  };

  const handlePackageSelect = (event: any) => {
    const selectedIds = event?.map((item: any) => item.value);
    setSelectedPackageIds(selectedIds);
  };

  return (
    <Box>
      {mode !== "list" && (
        <CouponCodeView
          couponcode={singlecouponcode}
          onSubmit={handleSubmit}
          handleBack={handleBack}
          mode={mode}
          allTempleList={templeList}
          allPujaList={allPujaList}
          handleTempleSelect={handleTempleSelect}
          handlePujaSelect={handlePujaSelect}
          allPackagesList={allPackagesList}
          handlePackageSelect={handlePackageSelect}
          seletedTempleOptions={seletedTempleOptions}
          seletedPujaOptions={seletedPujaOptions}
          seletedPackageOptions={seletedPackageOptions}
        />
      )}
      {mode === "list" && (
        <CouponCodeList
          codelist={codeList}
          handleAdd={handleAdd}
          handleEdit={handleEdit}
          handleDelete={handleClickOpen}
        />
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this row?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default CouponCodeScreen;
