import React from "react";
import "./AIGurujiReligiousChat.scss";
import { Button, Stack, Box, Avatar, Typography } from "@mui/material";
import Nameste from "../../../../assets/nameste.png";
import valmikiImg from "../../../../assets/valmikiImg.png";
import musicicon from "../../../../assets/music icon.png";
import gpticon from "../../../../assets/gpticon.png";
import AIGurujiValmikiImg from "../AIGurujiValmikiImg/AIGurujiValmikiImg";
import AIGurujitext from "../AIGurujitext/AIGurujitext";
const AIGurujiReligiousChat: React.FC<any> = ({ availableActions, onMenuSelect, isDisabled, direction,selectedServices }) => {
  const handleOnMenuSelect = (item: any) => {
    if (!isDisabled) {
      onMenuSelect(item);
    }

  }

  const isRow = direction === 'row';
  return (
    <Box className="religiousChatContainer">
      <Box className={`messageContainer ${isRow ? '' : 'left'}`}>
        <Stack
         className="chatItemSuggestionBtn gapAdded"
          direction={isRow ? 'row' : 'column'}
          spacing={2}
          justifyContent={isRow ? 'flex-end' : 'left'}
          alignItems={isRow ? 'flex-end' : 'left'}
        >
         
            {availableActions?.map((item: any, index = 0) => (
              <Box className={`messageBox whiteBox `} key={` ReligiousChat${index}`} >
                <Typography className="message-text" sx={{ cursor: "pointer" }} onClick={e => { e.preventDefault(); handleOnMenuSelect(item) }}>
                  

                  <Box component="img"
                    src={item?.icon}
                    alt={item?.name}
                    
                  >
                  </Box>
                  {item?.name}
                </Typography>
              </Box>
            ))}
          
        </Stack>
      </Box>

    </Box>
  );
};

export default AIGurujiReligiousChat;
