import { getOfferData } from "../store/actions/authAction";
import { setCartAction } from "../store/actions/cartAction";
import { getPriceConversionRate } from "../store/actions/priceConversionAction";
import {
  getPromotionalPujaList,
  getTemplePujaCategoryList,
} from "../store/actions/pujaAction";

import {
  getAllTempleList,
  getBenefitsList,
  getPanchangData,
  getTempleAndPujaList,
  getUnverifiedTempleList,
  getVerrifiedTempleList,
} from "../store/actions/templeAction";
import { setSelectedAddressId } from "../store/slices/userSlice";
function LoadInitialData(dispatch: any) {
  // dispatch(getUnverifiedTempleList({}));
  dispatch(getVerrifiedTempleList({}));
  // dispatch(getAllTempleList({}));
  dispatch(getTempleAndPujaList());
  dispatch(getPromotionalPujaList());
  dispatch(getBenefitsList());
  dispatch(getTemplePujaCategoryList());
  dispatch(getOfferData());
  dispatch(getPriceConversionRate(null))
  if (localStorage["cartInfo"]) {
      let cartInfoFromLocalStorage = JSON.parse(localStorage.getItem("cartInfo") || "")
      dispatch(setCartAction(cartInfoFromLocalStorage));
  }
  if (localStorage["selectedAddressId"]) {
    let selectedAddressFromLocalStorage = localStorage.getItem("selectedAddressId")|| null
    dispatch( setSelectedAddressId(selectedAddressFromLocalStorage));
}
  
}

export default LoadInitialData;
