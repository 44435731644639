import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function MainCategoriesCard(props: any) {
  return (
    <Box
      className="card templeCard"
      onClick={() => props.navigate(props.navigatePath)}
    >
      <Box className="card-img-area">
        <img src={props.image} className="card-img-top" alt="..."></img>
      </Box>
      <Box className="card-body">
        <Typography className="card-title">{props.cardTitle}</Typography>
      </Box>
    </Box>
  );
}
