import { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ParentView from "../../Views/PrarentView/ParentView";
import SearchView from "../../Views/Search/SearchView";
import { mainCategories } from "../../utils/Constants";
import pujaImg from "../../assets/puja.jpg";
import templeImg from "../../assets/temple.jpg";
function SearchScreen() {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const location = useLocation();
  const [mergedListWithIndex, setMergedListWithIndex]: any = useState([]);
  const [listToshow, setListToShow]: any = useState([]);
  const [updatedCategoryList, setUpdatedCategoryList]: any = useState([]);
  const [isCategoryView, setIsCategoryView]: any = useState(false);
  const [categoryDetails, setCategoryDetails]: any = useState(null);
  const { allTempleList, mergedList } = useSelector(
    (state: any) => state.temple
  );
  const { pujaCategoryList } = useSelector((state: any) => state.puja);

  useEffect(() => {
    if (mergedList && mergedList.length > 0) {
      makeLisIndexForSearch();
    }
  }, [mergedList]);

  useEffect(() => {
    const searchCateGoryData = location.pathname.split("/search/category/");
    if (searchCateGoryData && searchCateGoryData.length > 1) {
      let category = searchCateGoryData[1];
      let filterCategoryRes = pujaCategoryList.filter(
        (item: any) => item.categoryId === category
      );
      if (filterCategoryRes && filterCategoryRes.length > 0) {
        setIsCategoryView(true);
        setCategoryDetails(filterCategoryRes[0]);
      }
    } else {
    }
  }, [pujaCategoryList]);

  useEffect(() => {
    let appendItems = [
      {
        id: "temp00001",
        url: templeImg,
        categoryId: "temples",
        name: "Temples",
      },
      {
        id: "puja00002",
        url: pujaImg,
        categoryId: "pujas",
        name: "Pujas",
      },
    ];

    setUpdatedCategoryList([...pujaCategoryList]);
  }, [pujaCategoryList]);

  const makeLisIndexForSearch = () => {
    let finalList: any = [];
    for (let i = 0; i < mergedList.length; i++) {
      let searchString = "";
      if (mergedList[i].dataType === "temple") {
        const templeName = mergedList[i]?.personalInfo.name;
        const templeAddress =
          mergedList[i]?.personalInfo?.address?.formatted_address;
        searchString = templeName + templeAddress;
        finalList = [
          ...finalList,
          { ...mergedList[i], searchString: searchString },
        ];
      } else if (mergedList[i].dataType === "templePuja") {
        const pujaName = mergedList[i]?.pujaDetails?.pujaName;
        const templeName = mergedList[i]?.personalInfo.name;
        const templeAddress =
          mergedList[i]?.personalInfo?.address?.formatted_address;
        searchString = pujaName + templeName + templeAddress;
        finalList = [
          ...finalList,
          { ...mergedList[i], searchString: searchString },
        ];
      } else {
      }
    }
    setMergedListWithIndex(finalList);
    setListToShow(finalList);
  };

  const onClickNavigateTemple = (data: any) => {
    console.log("Navigate temple", data);
    navigate(`/temples/${data.id}`);
  };

  const onClickNavigatePuja = (data: any) => {
    console.log("Participate now data", data);
    navigate(`/temples/${data.id}/pujaInfo/${data?.pujaDetails?.pujaId}`);
  };

  const navigateToPages = (navigatePath: any) => {
    navigate(navigatePath);
  };

  const searchTempleAndPujaForUser = (searchInput: any) => {
    const userInputLC = searchInput.toLowerCase();

    let visibleList: any = [];
    for (let i = 0; i < mergedListWithIndex.length; i++) {
      const searchStrinLC =
        mergedListWithIndex[i] && mergedListWithIndex[i].searchString
          ? mergedListWithIndex[i].searchString.toLowerCase()
          : "";
      if (searchStrinLC.includes(userInputLC)) {
        visibleList = [...visibleList, mergedListWithIndex[i]];
      }
    }
    setListToShow(visibleList);
    setIsCategoryView(false);
  };

  const selectCategory = (categoryItem:any)=>{
    console.log("Category selected",categoryItem.categoryId);
    let catId = categoryItem.categoryId
    navigate('/search/category/'+catId);
    if(catId!=='temples'&&catId!=='pujas'){
      setIsCategoryView(true);
      setCategoryDetails(categoryItem);
    }
    else
    {
      setIsCategoryView(false);
      setCategoryDetails(categoryItem);
    }
  }

  const slectSubCategory = (catId:any)=>{
    navigate('/pujaSubCategory/'+catId)
  }

  // console.log("List to show", listToshow);
  return (
    <ParentView>
      <SearchView
        mainCategories={mainCategories}
        mergedList={listToshow}
        onClickNavigateTemple={onClickNavigateTemple}
        onClickNavigatePuja={onClickNavigatePuja}
        navigateToPages={navigateToPages}
        searchTempleAndPujaForUser={searchTempleAndPujaForUser}
        pujaCategoryList={updatedCategoryList}
        categoryDetails={categoryDetails}
        isCategoryView={isCategoryView}
        selectCategory={selectCategory}
        slectSubCategory={slectSubCategory}
      />
    </ParentView>
  );
}

export default SearchScreen;
